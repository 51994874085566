<template>
  <div class="littleMessage">
    <div class="wrap">
      <p class="type">小新快讯</p>
      <router-link to="/newsList" class="more">查看更多</router-link>
      <div
        class="box"
        v-for="(item, i) in mess.data"
        :key="i"
        @click="goDetail(item.id)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="content">{{ item.resume }}</div>
        <div class="line" v-if="i < 1"></div>
      </div>
    </div>
    <div class="line2"></div>

    <div class="wrap">
      <p class="type">行业动态</p>
      <router-link to="/newsList" class="more">查看更多</router-link>
      <div
        class="box"
        v-for="(item, i) in active.data"
        :key="i"
        @click="goDetail(item.id)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="content">{{ item.resume }}</div>
        <div class="line" v-if="i < 1"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from "vue";
import axios from "@/utils/axios";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/生涯资讯/生涯资讯.jpg"),
      ],
      list: [
        {
          title: "1",
          content:
            "送大的那三單啊賽單啊開機送大的那三單啊賽單啊開機送大的那三單單啊賽單啊開機送大的那三單啊賽單啊開機",
        },
        { title: "2", content: "送大的那三單啊賽單啊開機" },
      ],
    };
  },
  methods: {
    goXSY() {
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      // window.open("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard")
    },
    goDetail(id: any) {
      // console.log(id);
      this.$router.push({ name: "newsDetail", params: { id: id } });
      setTimeout(() => {
        location.reload();
      }, 1000);
    },
  },
  setup() {
    var mess = reactive({ data: "" });
    var active = reactive({ data: "" });
    axios
      .get(
        "https://www.xsy985.com/api/news/news?page=1&page_size=2&news_type=1"
      )
      .then(function (response) {
        console.log(response.data);
        mess.data = response.data.results;
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(
        "https://www.xsy985.com/api/news/news/?page=1&page_size=2&news_type=2"
      )
      .then(function (response) {
        console.log(response.data);
        active.data = response.data.results;
      })
      .catch(function (error) {
        console.log(error);
      });
    return {
      mess,
      active,
    };
  },
});
</script>
<style lang='scss' scoped>
.littleMessage {
  .line {
    width: 310px;
    height: 1px;
    background-color: #3ebbbd;
    margin: 30px 0;
  }
  .line2 {
    width: 310px;
    float: left;
    height: 2px;
    background-color: #3ebbbd;
    margin: 30px 0;
  }
  .wrap {
    width: 310px;
    display: block;
    .type {
      font-size: 24px;
      color: #3ebbbd;
      font-weight: bold;
      float: left;
      margin-bottom: 30px;
    }
    .more {
      float: right;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 30px;
      cursor: pointer;
    }

    .box {
      float: left;
      width: 310px;
      // background-color: #fff;
      .title {
        font-size: 16px;
        color: #3ebbbd;
        line-height: 20px;
        margin-bottom: 30px;
      }
      .content {
        line-height: 20px;
        font-size: 14px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical；;
      }
    }
  }
}
</style>
