
import { defineComponent, reactive } from "vue";
import axios from "@/utils/axios";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/生涯资讯/生涯资讯.jpg"),
      ],
      list: [
        {
          title: "1",
          content:
            "送大的那三單啊賽單啊開機送大的那三單啊賽單啊開機送大的那三單單啊賽單啊開機送大的那三單啊賽單啊開機",
        },
        { title: "2", content: "送大的那三單啊賽單啊開機" },
      ],
    };
  },
  methods: {
    goXSY() {
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      // window.open("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard")
    },
    goDetail(id: any) {
      // console.log(id);
      this.$router.push({ name: "newsDetail", params: { id: id } });
      setTimeout(() => {
        location.reload();
      }, 1000);
    },
  },
  setup() {
    var mess = reactive({ data: "" });
    var active = reactive({ data: "" });
    axios
      .get(
        "https://www.xsy985.com/api/news/news?page=1&page_size=2&news_type=1"
      )
      .then(function (response) {
        console.log(response.data);
        mess.data = response.data.results;
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(
        "https://www.xsy985.com/api/news/news/?page=1&page_size=2&news_type=2"
      )
      .then(function (response) {
        console.log(response.data);
        active.data = response.data.results;
      })
      .catch(function (error) {
        console.log(error);
      });
    return {
      mess,
      active,
    };
  },
});
